import React from "react";

import CardMenu from "components/card/CardMenu";
import { DiApple } from "react-icons/di";
import { DiAndroid } from "react-icons/di";
import { DiWindows } from "react-icons/di";
import Card from "components/card";
import Progress from "components/progress";
import Pagination from "@material-ui/lab/Pagination";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

// Add columnHelper here
const columnHelper = createColumnHelper();

export const columns = [
  columnHelper.accessor("firstName", {
    id: "firstName",
    header: () => (
      <p className="text-sm font-bold text-gray-600 dark:text-white">
        FIRST NAME
      </p>
    ),
    cell: (info) => (
      <p className="text-sm font-bold text-navy-700 dark:text-white">
        {info.getValue()}
      </p>
    ),
  }),
  columnHelper.accessor("lastName", {
    id: "lastName",
    header: () => (
      <p className="text-sm font-bold text-gray-600 dark:text-white">
        LAST NAME
      </p>
    ),
    cell: (info) => (
      <p className="text-sm font-bold text-navy-700 dark:text-white">
        {info.getValue()}
      </p>
    ),
  }),
  columnHelper.accessor("email", {
    id: "email",
    header: () => (
      <p className="text-sm font-bold text-gray-600 dark:text-white">EMAIL</p>
    ),
    cell: (info) => (
      <p className="text-sm font-bold text-navy-700 dark:text-white">
        {info.getValue()}
      </p>
    ),
  }),
  columnHelper.accessor("status", {
    id: "status",
    header: () => (
      <p className="text-sm font-bold text-gray-600 dark:text-white">STATUS</p>
    ),
    cell: (info) => (
      <p className="text-sm font-bold text-navy-700 dark:text-white">
        {info.getValue()}
      </p>
    ),
  }),
  columnHelper.accessor("membershipNumber", {
    id: "membershipNumber",
    header: () => (
      <p className="text-sm font-bold text-gray-600 dark:text-white">
        MEMBERSHIP NUMBER
      </p>
    ),
    cell: (info) => (
      <p className="text-sm font-bold text-navy-700 dark:text-white">
        {info.getValue()}
      </p>
    ),
  }),
  columnHelper.accessor("profileType", {
    id: "profileType",
    header: () => (
      <p className="text-sm font-bold text-gray-600 dark:text-white">
        PROFILE TYPE
      </p>
    ),
    cell: (info) => (
      <p className="text-sm font-bold text-navy-700 dark:text-white">
        {info.getValue() === "NONE" ? "Other" : info.getValue()}
      </p>
    ),
  }),

  columnHelper.accessor("countryName", {
    id: "countryName",
    header: () => (
      <p className="text-sm font-bold text-gray-600 dark:text-white">Country</p>
    ),
    cell: (info) => {
      // Assuming `country` is an array and you want to access the first element's `name`
      const countryName = info.getValue();
      return (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {countryName ? countryName : "N/A"}
        </p>
      );
    },
  }),
  columnHelper.accessor("city", {
    id: "city",
    header: () => (
      <p className="text-sm font-bold text-gray-600 dark:text-white">City</p>
    ),
    cell: (info) => {
      const cityName = info.getValue();
      return (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {cityName ? cityName : "N/A"}
        </p>
      );
    },
  }),

  columnHelper.accessor("industryName", {
    id: "industryName",
    header: () => (
      <p className="text-sm font-bold text-gray-600 dark:text-white">
        Industry
      </p>
    ),
    cell: (info) => {
      // Assuming `industry` is an array and you want to access the first element's `name`
      const industryName = info.getValue();
      return (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {industryName ? industryName : "N/A"}
        </p>
      );
    },
  }),

  columnHelper.accessor("companyName", {
    id: "companyName",
    header: () => (
      <p className="text-sm font-bold text-gray-600 dark:text-white">Company</p>
    ),
    cell: (info) => {
      // Assuming `companies` is an array and you want to access the first element's `name`
      const companyName = info.getValue();
      return (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {companyName ? companyName : "N/A"}
        </p>
      );
    },
  }),

  columnHelper.accessor("createdAt", {
    id: "createdAt",
    header: () => (
      <p className="text-sm font-bold text-gray-600 dark:text-white">
        REGISTRATION DATE
      </p>
    ),
    cell: (info) => {
      const date = new Date(info.getValue());
      const formattedDate = date.toLocaleDateString();
      return (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {formattedDate}
        </p>
      );
    },
  }),
  // Adding new Country column
  // columnHelper.accessor("country", {
  //   id: "country",
  //   header: () => (
  //     <p className="text-sm font-bold text-gray-600 dark:text-white">COUNTRY</p>
  //   ),
  //   cell: (info) => (
  //     <p className="text-sm font-bold text-navy-700 dark:text-white">
  //       {info.getValue()}
  //     </p>
  //   ),
  // }),
  // // Adding new Company column
  // columnHelper.accessor("company", {
  //   id: "company",
  //   header: () => (
  //     <p className="text-sm font-bold text-gray-600 dark:text-white">COMPANY</p>
  //   ),
  //   cell: (info) => (
  //     <p className="text-sm font-bold text-navy-700 dark:text-white">
  //       {info.getValue()}
  //     </p>
  //   ),
  // }),
];

function CheckTable(props) {
  const {
    tableData,
    handleItemsPerPageChange,
    handlePageChange,
    currentPage,
    totalCount,
    itemsPerPage,
  } = props;
  const [sorting, setSorting] = React.useState([]);
  let defaultData = tableData;

  const [data, setData] = React.useState(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>
      <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
        <table className="w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="!border-px !border-gray-400">
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      onClick={header.column.getToggleSortingHandler()}
                      className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                    >
                      <div className="items-center justify-between text-xs text-gray-200">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {header.column.getIsSorted() ? (
                          <span>
                            {header.column.getIsSorted() === "asc" ? "↑" : "↓"}
                          </span>
                        ) : null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table
              .getRowModel()
              .rows.slice(0)
              .map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          className="min-w-[150px] border-white/0 py-3 pr-4"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>

        <div className="mt-4 flex items-center justify-between">
          <Pagination
            count={Math.ceil(totalCount / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            size="small"
            showFirstButton
            showLastButton
          />
          <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
      </div>
    </Card>
  );
}

export default CheckTable;
