import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios"; // Assuming you're using Axios for API calls

import InputField from "components/fields/InputField";
import Checkbox from "components/checkbox";
import Loader from "components/loader";

export default function SignIn() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false); // State for loading indicator
  const [error, setError] = useState(null); // State for error message

  const handleSignIn = async (e) => {
    e.preventDefault();

    setIsLoading(true); // Set loading indicator to true
    setError(null); // Clear any previous errors
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/user/login`,
        {
          email,
          password,
        }
      );
      const accessToken = response.data.data.access_token; // Assuming the access token is in the 'accessToken' property
      localStorage.setItem("accessToken", accessToken);

      // Handle successful login
      console.log("Login successful:", response.data);
      const isAdmin = response.data.data.isAdmin;

      if (isAdmin) {
        navigate("/admin", { replace: true });
      } else {
        setError("You do not have permission to access the admin dashboard.");
      }
    } catch (error) {
      // Handle login errors
      console.error("Login error:", error);
      setError(
        error.response?.data?.message ||
          "Login failed. Please check your credentials."
      );
    } finally {
      setIsLoading(false); // Set loading indicator to false
    }
  };

  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter your email and password to sign in!
        </p>

        {/* Email */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Email*"
          placeholder="mail@simmmple.com"
          id="email"
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        {/* Password */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Password*"
          placeholder="Min. 8 characters"
          id="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        {/* Checkbox */}
        <div className="mb-4 flex items-center justify-between px-2">
          <div className="flex items-center">
            <Checkbox />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
              Keep me logged In
            </p>
          </div>
          {/* <a className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white" href=" ">
            Forgot Password?
          </a> */}
        </div>


        {error && (
          <p className="text-center text-sm font-medium text-red-500">
            {error}
          </p>
        )}

        {isLoading ? <Loader size={10} /> : 
        <button
          className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          onClick={handleSignIn}
          disabled={isLoading} // Disable button while loading
        >
          Sign In
        </button>}
      </div>
    </div>
  );
}
