import React, { useState, useEffect } from "react";
import CheckTable from "./components/CheckTable";
import {
  columnsDataCheck,
  columnsDataDevelopment,
} from "./variables/columnsData";
import DevelopmentTable, { columns } from "./components/DevelopmentTable";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import MiniCalendar from "components/calendar/MiniCalendar";
import Dropdown from "components/dropdown";
import { Avatar } from "@material-ui/core";
import FiltersSection from "./components/FilterSection";
import Loader from "components/loader";

const Tables = () => {
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [filters, setFilters] = useState({
    company: "",
    industry: "",
    country: "",
    registrationDate: null,
    profileType: "",
  });
  const [appliedFilters, setAppliedFilters] = useState({}); // Track applied filters
  const [companyOptions, setCompanyOptions] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);

  const filtersConfig = [
    {
      type: "dropdown",
      name: "country",
      options: countryOptions,
      isMulti: true,
      placeholder: "Select Country",
    },
    {
      type: "dropdown",
      name: "indusry",
      options: industryOptions,
      isMulti: true,
      placeholder: "Select Industry",
    },
    {
      type: "dropdown",
      name: "companies",
      options: companyOptions.map((item) => ({
        ...item,
        label: item.name,
        value: item.id,
      })),
      isMulti: true,
      placeholder: "Select Company",
    },
    {
      type: "text",
      name: "name",
      placeholder: "Search by Name",
    },
    {
      type: "dateRange",
      name: "dateRange",
    },
  ];

  const fetchAllUsers = async (
    page = 1,
    perPage = itemsPerPage,
    filterParams = appliedFilters
  ) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        throw new Error("Missing access token");
      }
      const filtersString = encodeURIComponent(JSON.stringify(filterParams));

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/user?skip=${
          (page - 1) * perPage
        }&take=${perPage}&filters=${filtersString}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch users");
      }

      const data = await response.json();
      return [...data.data];
    } catch (err) {
    } finally {
    }
  };

  const fetchUsers = async (
    page = 1,
    perPage = itemsPerPage,
    filterParams = appliedFilters
  ) => {
    setIsLoading(true);
    setError(null);

    try {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        throw new Error("Missing access token");
      }

      const filtersString = encodeURIComponent(JSON.stringify(filterParams));
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/user?skip=${
          (page - 1) * perPage
        }&take=${perPage}&filters=${filtersString}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch users");
      }

      const data = await response.json();
      setUserList(data.data);
      setCurrentPage(data.meta.currentPage);
      setItemsPerPage(data.meta.itemsPerPage);
      setTotalCount(data.meta.totalCount);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Export to CSV function
  const exportToCSV = async () => {
    const allUsers = await fetchAllUsers(1, 1000, appliedFilters);
    const excludedFields = [
      "password",
      "verificationTokenExpiry",
      "verificationToken",
      "verificationOtp",
      "isAdmin",
      "countryDetails",
      "companyDetails",
      "industryDetails",
      "userCompanyRoles",
      "__v",
    ];

    const filteredData = allUsers.map((row) => {
      const filteredRow = {};
      Object.keys(row).forEach((key) => {
        filteredRow[key] = row[key];
      });
      return filteredRow;
    });

    const headers = columns
      .filter((col) => !excludedFields.includes(col.id)) // Filter columns
      .map((col) => col.header().props.children);

    const csvRows = [];
    csvRows.push(headers.join(","));

    filteredData.forEach((row) => {
      const values = columns
        .filter((col) => !excludedFields.includes(col.id)) // Filter columns
        .map((col) => {
          let value = "";

          if (
            col.id === "country" ||
            col.id === "companies" ||
            col.id === "industry"
          ) {
            value = row[col.id]?.name || "";
          } else {
            if (col.id === "createdAt") {
              const date = new Date(row[col.id]).toLocaleString();
              value = date;
            } else if (col.id === "profileType" && row[col.id] === "NONE") {
              value = "Other";
            } else {
              value = row[col.id];
            }
          }

          if (value && value.includes(",")) {
            value = `"${value.replace(/"/g, '""')}"`;
          }

          return value;
        });

      csvRows.push(values.join(","));
    });

    const csvData = new Blob([csvRows.join("\n")], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvData);
    const a = document.createElement("a");
    a.href = url;

    const date = new Date();
    const timestamp = `${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}-${String(
      date.getHours()
    ).padStart(2, "0")}_${String(date.getMinutes()).padStart(2, "0")}`;
    const filename = `Users_Data_${timestamp}.csv`;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  // Export to Excel function
  const exportToExcel = async () => {
    const allUsers = await fetchAllUsers(1, 1000, appliedFilters);
    const excludedFields = [
      "_id",
      "isVerified",
      "password",
      "verificationTokenExpiry",
      "verificationToken",
      "verificationOtp",
      "isAdmin",
      "country",
      "industry",
      "companies",
      "countryDetails",
      "companyDetails",
      "industryDetails",
      "userCompanyRoles",
      "profileUrl",
      "linkedin",
      "updatedAt",
      "__v",
    ];

    const filteredData = allUsers.map((row) => {
      const filteredRow = {};
      Object.keys(row).forEach((key) => {
        if (!excludedFields.includes(key)) {
          if (key === "country" || key === "companies" || key === "industry") {
            filteredRow[key] = row[key][0]?.name || "";
          } else {
            if (key === "createdAt") {
              const date = new Date(row[key]).toLocaleString();
              filteredRow["registrationDate"] = date;
            } else if (key === "profileType" && row[key] === "NONE") {
              filteredRow[key] = "Other";
            } else filteredRow[key] = row[key];
          }
        }
      });
      return filteredRow;
    });

    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Data");

    const date = new Date();
    const timestamp = `${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}-${String(
      date.getHours()
    ).padStart(2, "0")}_${String(date.getMinutes()).padStart(2, "0")}`;
    const filename = `Users_Data_${timestamp}.xlsx`;
    XLSX.writeFile(wb, filename);
  };

  const fetchData = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        throw new Error("Missing access token");
      }
      setIsLoading(true);

      const companyPromise = fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/companies?skip=0&take=1000`
      );
      const industryPromise = fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/industries?skip=0&take=200`
      );
      const countryPromise = fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/countries?skip=0&take=300`
      );
      const usersPromise = fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/user?skip=${
          (currentPage - 1) * itemsPerPage
        }&take=${itemsPerPage}`,

        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      // Wait for all requests to complete concurrently
      const [companyRes, industryRes, countryRes, usersRes] = await Promise.all(
        [companyPromise, industryPromise, countryPromise, usersPromise]
      );

      if (
        // !companyRes.ok || !industryRes.ok || !countryRes.ok ||
        !usersRes.ok
      ) {
        throw new Error("Failed to fetch data");
      }

      // Parse the JSON responses
      const companyData = await companyRes.json();
      const industryData = await industryRes.json();
      const countryData = await countryRes.json();
      const usersData = await usersRes.json();

      console.log();
      // Set the state for the fetched data
      setCompanyOptions([
        ...companyData.data.map((item) => ({
          ...item,
          label: item.name,
          value: item._id,
        })),
      ]);
      setIndustryOptions([
        ...industryData.data.map((item) => ({
          ...item,
          label: item.name,
          value: item._id,
        })),
      ]);
      setCountryOptions([
        ...countryData.data.map((item) => ({
          ...item,
          label: item.name,
          value: item._id,
        })),
      ]);
      setUserList(usersData.data);
      setTotalCount(usersData.meta.totalCount);
      setCurrentPage(usersData.meta.currentPage);
      setItemsPerPage(usersData.meta.itemsPerPage);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const applyFilters = (filters) => {
    const filterParams = {};

    if (filters.country && filters.country.length > 0) {
      filterParams.country = filters.country.map((item) => item.label); // Use .label or any key representing the country name
    }

    if (filters.indusry && filters.indusry.length > 0) {
      filterParams.indusry = filters.indusry.map((item) => item.label); // Use .label or any key representing the industry name
    }

    if (filters.companies && filters.companies.length > 0) {
      filterParams.company = filters.companies.map((item) => item.label); // Use .label or any key representing the company name
    }
    setFilters(filters);
    setAppliedFilters(filterParams); // Save the filters for pagination

    // Call the fetchUsers function with the new filter parameters
    fetchUsers(currentPage, itemsPerPage, filterParams);
  };

  const handlePageChange = (e, newPage) => {
    setCurrentPage(newPage);
    fetchUsers(newPage, itemsPerPage, appliedFilters); // Use applied filters for paginated data
  };

  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = e.target.value;
    setItemsPerPage(newItemsPerPage);
    fetchUsers(1, newItemsPerPage); // Use applied filters for new page size
  };

  useEffect(() => {
    fetchData(); // Fetch initial data
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className="h-screen w-full">
          <Loader size={16} />
        </div>
      ) : error ? (
        <div className="h-screen w-full">
          <p>Error: {error}</p>
        </div>
      ) : (
        <div className="mt-8">
          <FiltersSection
            exitingFilters={filters}
            filtersConfig={filtersConfig}
            onApplyFilters={applyFilters}
          />
          <div className="mb-4 mt-4 flex justify-end space-x-4">
            <button onClick={exportToCSV} className="btn btn-primary">
              Export to CSV
            </button>
            <button onClick={exportToExcel} className="btn btn-primary">
              Export to Excel
            </button>
          </div>
          <div className="overflow-x-auto" style={{ maxWidth: "100%" }}>
            <table style={{ minWidth: "100%" }} className="w-full table-auto">
              <DevelopmentTable
                columnsData={columnsDataDevelopment}
                tableData={userList}
                handleItemsPerPageChange={handleItemsPerPageChange}
                handlePageChange={handlePageChange}
                currentPage={currentPage}
                totalCount={totalCount}
                itemsPerPage={itemsPerPage}
              />
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default Tables;
