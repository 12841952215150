import React, { useMemo } from "react";

const Loader = ({ size = 16, borderColor = "border-primary" }) => {
  return (
    <div className="flex h-[100%] items-center justify-center">
      <div
        className={`h-${size} w-${size} m-auto animate-spin rounded-full border-4 border-solid border-primary border-t-transparent`}
      ></div>
    </div>
  );
};

export default Loader;
