import React from 'react';
import Select from 'react-select';

const FilterDropdown = ({ options, isMulti = false, placeholder, value, onChange, name }) => {
  return (
    <div className="min-w-[200px]">
      <Select
        options={options}
        isMulti={isMulti}
        placeholder={placeholder}
        value={value}
        onChange={(selected) => onChange(name, selected)}
        className="text-sm"
      />
    </div>
  );
};

export default FilterDropdown;
