import React, { useState } from 'react';
import FilterDropdown from '../../../../components/multi-select-dropdown';


const FiltersSection = ({exitingFilters, filtersConfig, onApplyFilters }) => {
  const [filters, setFilters] = useState(exitingFilters||{});

  const handleFilterChange = (name, value) => {
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const applyFilters = () => {
    onApplyFilters(filters);
  };

    const clearFilters = () => {
    setFilters({});
    onApplyFilters({});
  };

  return (
    <div className="flex flex-wrap gap-4 mb-4">
      {filtersConfig.map((filter) => {
        if (filter.type === 'dropdown') {
          return (
            <FilterDropdown
              key={filter.name}
              name={filter.name}
              options={filter.options}
              isMulti={filter.isMulti}
              placeholder={filter.placeholder}
              value={filters[filter.name] || (filter.isMulti ? [] : null)}
              onChange={handleFilterChange}
            />
          );
        }
        // if (filter.type === 'text') {
        //   return (
        //     <FilterTextField
        //       key={filter.name}
        //       name={filter.name}
        //       placeholder={filter.placeholder}
        //       value={filters[filter.name] || ''}
        //       onChange={handleFilterChange}
        //     />
        //   );
        // }
        // if (filter.type === 'dateRange') {
        //   return (
        //     <FilterDateRange
        //       key={filter.name}
        //       name={filter.name}
        //       startDate={filters[filter.name]?.startDate || ''}
        //       endDate={filters[filter.name]?.endDate || ''}
        //       onChange={handleFilterChange}
        //     />
        //   );
        // }
        return null;
      })}
      <button
        onClick={applyFilters}
        className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
      >
        Apply Filters
      </button>

              <button
          onClick={clearFilters}
          className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400"
        >
          Clear Filters
        </button>

   
    </div>
  );
};

export default FiltersSection;
