import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import RtlLayout from "layouts/rtl";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import ProtectedRoute from "./protectedRoute"; // Import the ProtectedRoute component

const App = () => {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="auth/*" element={<AuthLayout />} />
      
      {/* Protected Routes */}
      <Route
        path="admin/*"
        element={
          <ProtectedRoute>
            <AdminLayout />
          </ProtectedRoute>
        }
      />
      
      {/* Redirect to Auth */}
      <Route path="/" element={<Navigate to="/auth" replace />} />
    </Routes>
  );
};

export default App;
