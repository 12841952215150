import React, { useEffect, useState } from "react";
import axios from "axios";
import { MdBarChart } from "react-icons/md";
import Widget from "components/widget/Widget";

const Dashboard = () => {
  // State to store API data
  const [dashboardData, setDashboardData] = useState({
    totalUsers: 0,
    pendingUsers: 0,
    investors: 0,
    moverShakers: 0,
    entrepreneurs: 0,
    others: 0,
  });

  // Fetch data from API
  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/user/stats`
        );
        setDashboardData(response.data.data); // Update state with API response
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };

    fetchDashboardData();
  }, []); // Empty dependency array means this runs once when component mounts

  return (
    <div>
      {/* Card widgets */}
      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Total Users"}
          subtitle={dashboardData.totalUsers}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Pending Users"}
          subtitle={dashboardData.pendingUsers}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Investors"}
          subtitle={dashboardData.investors}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Mover Shakers"}
          subtitle={dashboardData.moverShakers}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Entrepreneurs"}
          subtitle={dashboardData.entrepreneurs}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Others"}
          subtitle={dashboardData.others}
        />
      </div>

      {/* Add other sections like charts and tables here if necessary */}
    </div>
  );
};

export default Dashboard;
