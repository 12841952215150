import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const isLoggedIn = localStorage.getItem("accessToken") !== null;

  return isLoggedIn ? children : <Navigate to="/auth" replace />;
};

export default ProtectedRoute;
